import React, { Component } from 'react'
import Helmet from 'react-helmet'
import Hero from "../components/hero/hero"
import Introduction from "../components/introduction/introduction"
import Layout from "../components/layout/layout"
import { GlobalStyles } from "../styles/globalStyles"
import favicon from '../images/favicon.png'
import { ThemeProvider } from "styled-components"
import theme from "../styles/theme"
import Facts from "../components/facts/facts"
import Contact from "../components/contact/contact"
import Footer from "../components/footer/footer"
import ContactModal from "../components/contact-modal/contactModal"
import { getDefaultIndexContent } from "../content/defaultContent";
import { getCmsContent } from '../content/datoCms/cmsContent'
import { INDEX_QUERY } from '../content/datoCms/queries'
import { formatResult } from '../content/datoCms/resultFormatter';

export default class IndexPage extends Component {
  constructor(props){
    super(props);

    this.state = {
      modalIsOpen: false,
      content: getDefaultIndexContent()
    }

    getCmsContent(INDEX_QUERY)
      .then(result => this.setState({ content: formatResult(result.data) }))
  }

  openContactModal = () => this.setState({ modalIsOpen: true },
    () => document.getElementsByTagName('html')[0].style.overflowY = "hidden"
  );
  closeContactModal = () => this.setState({ modalIsOpen: false }, 
    () => document.getElementsByTagName('html')[0].style.overflowY = "scroll"
  );

  render() {
    return (
      <>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <Helmet>
              <title>Constantin Clown</title>
              <link rel="icon" type="image/png" href={favicon} sizes="32x32" />
              <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
          </Helmet>
          <main>
            <Layout>
              <Hero openContactForm={this.openContactModal} heroContent={this.state.content.hero} />
              <Introduction introContent={this.state.content.einleitung} />
              <Facts factsContent={this.state.content.faehigkeiten} />
              <Contact openContactForm={this.openContactModal} contactContent={this.state.content.contact} />
              <Footer openImprint={this.peekImprint} footerContent={this.state.content.contactFooter} />
              <ContactModal isOpen={this.state.modalIsOpen} closeHandler={this.closeContactModal} />
            </Layout>
          </main>
        </ThemeProvider>
      </>
    )
  }
}
