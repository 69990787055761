import { css } from "styled-components";

const MobileContactButton = css`
  background: transparent;
  border: 4px solid #9d004b;
  padding: 1rem;
  font-size: 20pt;
  font-family: 'New Paris Skyline', sans-serif;
  width: 90%;
  color: #9d004b;
  user-select: none;
  cursor: pointer;
`;

export { MobileContactButton }