import React from 'react';
import styled from "styled-components";
import Headers from './headers';
import { device } from "../../styles/devices"

const IntroductionWrapper = styled.div`
  padding: 5%;
  height: auto;

  @media ${device.laptop} {
    padding: 5rem 0;
    display: grid;
    grid-template-areas:  ". anjaHeader anjaHeader roccaHeader roccaHeader ."
                          ". anjaText . . roccaText ."
                          ". anjaText image image roccaText ."
                          ". anjaText . . roccaText .";
    grid-template-columns: 15% 1fr 1.5fr 1.5fr 1fr 15%;
    grid-template-rows: min-content(6rem) 1rem 31rem auto;
    
    position: relative;
  }
`;

const IntroImage = styled.img`
  max-width: 100%;
  
  @media ${device.laptop} {
    width: 80%;
    grid-area: image;
    margin: 0 auto;
  }
`;

const AnjaIntro = styled.p`
  margin-top: 2rem;
  white-space: break-spaces;

  @media ${device.laptop} {
    grid-area: anjaText;
    margin: 0;
  }
`;

const RoccaIntro = styled.p`
  margin-top: 2rem;
  color: ${props => props.theme.red};
  white-space: break-spaces;

  @media ${device.laptop} {
    margin: 0;
    grid-area: roccaText;
    color: black;
  }
`;

const Introduction = ({ introContent }) => {
  return (
    <IntroductionWrapper id="introduction">
      <Headers anja={introContent.anja} rocca={introContent.rocca} />
      <IntroImage src={introContent.image} />
      <AnjaIntro >
        { introContent.anja.intro }
      </AnjaIntro>
      <RoccaIntro>
        { introContent.rocca.intro }
      </RoccaIntro>
    </IntroductionWrapper>
  );
}

export default Introduction;
