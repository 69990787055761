import React, { Component } from 'react'
import styled from 'styled-components';
import Input from './input';
import Privacyconfirmation from './privacyConfirmation';
import TextArea from './textArea';
import { device } from "../../../styles/devices"
import { isValidEmail } from "../contactService";

const SubmitButton = styled.input`
  border: none;
  background: ${props => props.theme.red};
  color: white;
  width: 100%;
  height: 5rem;
  font-size: 23px;
  letter-spacing: 1px;
  cursor: pointer;
  -webkit-appearance: none;

  @media ${device.laptop} {
    height: 5rem;
  }
`;

const Form = styled.form`
  height: 100%;
  overflow-y: scroll;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 3fr 1fr 1fr;
  grid-template-areas:  "name"
                        "email"
                        "phone"
                        "message"
                        "privacy"
                        "submit";

  @media ${device.laptop} {
    grid-template-rows: 6.5rem 6.5rem 10rem 4rem 7rem;
    grid-column-gap: 1.5rem;
    grid-row-gap: .5rem;
    grid-template-areas:  ". name email ."
                          ". phone phone ."
                          ". message message ."
                          ". privacy privacy ."
                          ". submit submit .";
  }
`;

export default class contactForm extends Component {
  constructor(props){
    super(props);
    
    this.state = {
      form: {
        name: '',
        email: '',
        phone: '',
        message: '',
        privacyChecked: false,
      },
      errors: {}
    }
  }

  submitContact = async event => {
    event.preventDefault();
  
    if(await this.formIsValid()){
      this.props.submitHandler(this.state.form);
    }
  }

  handleChange = event => {
    const value = event.target.value;
    const name = event.target.name;

    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [name]: value
      }
    });
  }

  formIsValid = async () => {
    // Name
    if(this.state.form.name.trim() === ''){
       await this.setState({
         ...this.state,
         errors: {
           ...this.state.errors,
           name: "Pflichtfeld"
         }
       })
    } else {
      const errors = this.state.errors;
      delete errors["name"];
      this.setState({
        ...this.state,
        errors
      });
    }

    // E-Mail
    if(this.state.form.email.trim() === ''){
      await this.setState({
        ...this.state,
        errors: {
          ...this.state.errors,
          email: "Pflichtfeld"
        }
      });
    } else if(!isValidEmail(this.state.form.email.trim())){
      await this.setState({
        ...this.state,
        errors: {
          ...this.state.errors,
          email: "Bitte gib eine gültige E-Mail an."
        }
      })
    } else {
      const errors = this.state.errors;
      delete errors["email"];
      this.setState({
        ...this.state,
        errors
      });
    }

    // Message
    if(this.state.form.message.trim() === ''){
      await this.setState({
        ...this.state,
        errors: {
          ...this.state.errors,
          message: "Pflichtfeld"
        }
      })
    } else {
      const errors = this.state.errors;
      delete errors["message"];
      this.setState({
        ...this.state,
        errors
      });
    }

    if(!this.state.form.privacyChecked){
      await this.setState({
        ...this.state,
        errors: {
          ...this.state.errors,
          privacy: "Pflichtfeld"
        }
      })
    } else {
      const errors = this.state.errors;
      delete errors["privacy"];
      this.setState({
        ...this.state,
        errors
      });
    }
    console.log(this.state.errors);
    this.forceUpdate();
    return Object.keys(this.state.errors).length === 0;
  }

  handleCheckboxChange = event => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        privacyChecked: event.target.checked
      }
    });
  }

  render() {
    return (
      <Form onSubmit={e => this.submitContact(e)}>
        <Input 
          placeholder="Name" 
          style={{ gridArea: "name" }} 
          name="name" 
          onChange={e => this.handleChange(e)}
          error={this.state.errors["name"]}
        />
        <Input 
          placeholder="E-Mail"
          style={{ gridArea: "email" }} 
          name="email" 
          onChange={e => this.handleChange(e)}
          error={this.state.errors["email"]}
        />
        <Input 
          placeholder="Telefon (optional)"
          style={{ gridArea: "phone" }}
          name="phone"
          onChange={e => this.handleChange(e)}
        />
        <TextArea 
          placeholder="Nachricht" 
          style={{ gridArea: "message" }} 
          name="message" 
          onChange={e => this.handleChange(e)} 
          error={this.state.errors["message"]}
        />
        <Privacyconfirmation 
          style={{ gridArea: "privacy" }} 
          onChange={e => this.handleCheckboxChange(e)} 
          error={this.state.errors["privacy"]}
        />
        <SubmitButton 
          type="submit" 
          value="Senden" 
          style={{ gridArea: "submit" }} 
        />
      </Form>
    )
  }
}
