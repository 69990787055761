import React from 'react';
import styled from 'styled-components';
import { device } from '../../../styles/devices';

const Error = styled.div`
  color: ${props => props.theme.red};
  font-size: 30px;

  @media ${device.laptop} {
    padding-left: 1.5rem;
  }
`;

const ErrorMessage = () => {
  return (
    <Error>
      Beim Senden Ihrer Nachricht ist ein Fehler aufgetreten.<br />
      Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut oder<br /> kontaktieren Sie mich via Telefon oder E-Mail.
      <br /><br />
      Vielen Dank für Ihr Verständnis.
    </Error>
  );
}

export default ErrorMessage;
