import React from 'react';
import styled from 'styled-components';

const CheckboxElement = styled.input`
  left: 0;
  top: 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;

  &::before {
    width: 23px;
    height: 23px;
    border: 4px solid ${props => props.theme.red};
    display: block;
    content: "";
    float: left;
    margin-right: 5px;
    z-index: 5;
    position: relative;
    background-color: white;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  &:checked::before {
    box-shadow: inset 0px 0px 0px 4px #fff;
    background-color: ${props => props.theme.red}
  }
`;

const Checkbox = (props) => {
  return (
    <CheckboxElement type='checkbox' {...props} />
  );
}

export default Checkbox;
