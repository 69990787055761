import React from 'react';
import styled from 'styled-components';
import { device } from "../../styles/devices"
import Links from './links';

const FooterWrapper = styled.div`
  background: ${props => props.theme.red};
  padding: 1.5rem;
`;

const FooterContainer = styled.div`
  @media ${device.laptop} {
    width: 60%;
    margin: 0 auto;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
  }
`;

const Address = styled.div`
  font-size: 16px;
  text-align: center;
  color: white;
  letter-spacing: 1px;
  margin-bottom: 2rem;  


  @media ${device.laptop} {
    margin-bottom: 0;  
  }
`;

const Anchor = styled.a`
  color: #fff!important;
  text-decoration: none;
`;

const Footer = ({footerContent: contact}) => {
  return (
    <FooterWrapper>
      <FooterContainer>
        <Address>
          <Anchor href={contact.mapsLink}>
            { contact.name }<br />
            { contact.street}<br />
            { contact.city }<br />
          </Anchor>
          <Anchor href={`tel:${contact.telephone}`}>{ contact.telephone }</Anchor><br />
          <Anchor href={`mailto:${contact.email}`}>{ contact.email }</Anchor>  
        </Address>
        <Links />
      </FooterContainer>
    </FooterWrapper>
  );
}

export default Footer;
