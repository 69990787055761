import { AnchorLink } from 'gatsby-plugin-anchor-links';
import React from 'react';
import styled from 'styled-components';
import Checkbox from './checkbox';

const CheckboxLabel = styled.label`
  color: ${props => props.theme.red};
  line-height: 32px;
  font-size: 25px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  margin-left: .5rem;
  user-select: none;
`;

const PrivacyConfirmationElement = styled.div`
  display: flex;
  align-items: flex-start;
  height: calc(100% - 1.5rem);
  margin-bottom: 1rem;
`;

const Error = styled.div`
  z-index: 2;
  height: 1.2rem;
  font-size: 1rem;
`;

const Privacyconfirmation = ({error, style, ...props}) => {
  return (
    <div style={style}>
      <Error><b>{error}</b></Error>    
      <PrivacyConfirmationElement {...props}>
        <Checkbox id="privacy-confirmation" />
        <CheckboxLabel htmlFor="privacy-confirmation">Ich akzeptiere die <AnchorLink to="/impressum#privacy"><u>Datenschutzerklärung</u></AnchorLink>.</CheckboxLabel>
      </PrivacyConfirmationElement>
    </div>    
  );
}

export default Privacyconfirmation;
