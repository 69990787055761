import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { device } from '../../styles/devices';

const LinkWrapper = styled.div`
  a {
    text-decoration: none;
  }
`;

const LinkContent = styled.span`
  font-family: "Sang Bleu Empire", sans-serif;
  font-size: 20px;
  color: #ffffff;
  letter-spacing: 1px;
  display: block;
  text-align: center;

  @media ${device.laptop} {
    font-size: 20px;
  }
`;

const Links = () => {
  return (
    <LinkWrapper>
      <Link to="/impressum">
        <LinkContent>
          IMPRESSUM &amp;<br />
          DATENSCHUTZ
        </LinkContent>
      </Link>
    </LinkWrapper>
  );
}

export default Links;
