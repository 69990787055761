import React from 'react';
import styled from 'styled-components';
import { device } from '../../styles/devices';

const FactWrapper = styled.div`
  @media ${device.laptop} {
    width: 25%;
    display: flex;
    flex-direction: column;
  }
`;

const FactTitle = styled.h1`
  color: ${props => props.theme.red};
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 0;

  @media ${device.laptop} {
    margin-bottom: 1rem;
  }
`;

const FactList = styled.ul`
  margin-top: 0;
`;

const FactItem = styled.li`
  font-family: 'New Paris Skyline', sans-serif;
  letter-spacing: .5px;

  @media ${device.laptop} {
    margin-bottom: 1.5rem;
  }
`;

const Fact = ({title, items}) => {
  return (
    <FactWrapper>
      <FactTitle>{ title }</FactTitle>
      <FactList>
      {
        items.map((item, index) => (
          <FactItem key={index}>{ item }</FactItem>
        ))
      }
      </FactList>
    </FactWrapper>
  );
}

export default Fact;
