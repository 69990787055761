import React from "react";
import styled from "styled-components";
import { device } from "../../styles/devices";
import Herocontact from "./heroContact";
import HeroImage from "./heroImage";

const HeroWrapper = styled.div`
  height: 85vh;
  min-height: -webkit-fill-available;

  @media ${device.laptop} {
    height: 100vh;
  }
`;

const Hero = ({openContactForm, heroContent }) => {
  return (
    <HeroWrapper>
      <HeroImage />
      <Herocontact openContactForm={openContactForm} title={heroContent.contactButton} />
    </HeroWrapper>
  )
}

export default Hero;