export const formatResult = graphQlResult => {
  let data = graphQlResult;

  return {
    hero: {
      contactButton: data.startbildschirm.kontaktbutton
    },
    einleitung: {
      anja: {
        black: data.einleitung.anjaueberschriftschwarz,
        red: data.einleitung.anjaueberschriftrot,
        intro: data.einleitung.anjavorstellung,
      },
      rocca: {
        black: data.einleitung.roccaueberschriftschwarz,
        red: data.einleitung.roccaueberschriftrot,
        intro: data.einleitung.roccavorstellung,
      },
      image: data.einleitung.einleitungsbild.url
    },
    faehigkeiten: [
      {
        title: data.faehigkeiten.titel1,
        items: parseFacts(data.faehigkeiten.inhalt1)
      },
      {
        title: data.faehigkeiten.titel2,
        items: parseFacts(data.faehigkeiten.inhalt2)
      },
      {
        title: data.faehigkeiten.titel3,
        items: parseFacts(data.faehigkeiten.inhalt3)
      }
    ],
    contact: {
      header: {
        red: data.preisundkontakt.ueberschriftrot,
        black: data.preisundkontakt.ueberschriftschwarz,
      },
      image: data.preisundkontakt.bild.url,
      content: data.preisundkontakt.inhalt,
      button: data.preisundkontakt.kontaktbutton,
      subtitle: data.preisundkontakt.untertitel
    },
    contactFooter: {
      name: data.kontaktfooter.name,
      street: data.kontaktfooter.strasse,
      city: data.kontaktfooter.ort,
      telephone: data.kontaktfooter.telefon,
      email: data.kontaktfooter.email,
      mapsLink: data.kontaktfooter.googlemapslink
    }
  };
}

const parseFacts = factsString => {
  return factsString
          .split('\n')
          .map(line => 
            line.slice(1, line.length)
                .trim());
}