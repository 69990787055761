import React from 'react';
import styled from 'styled-components';
import Fact from './fact';
import { device } from "../../styles/devices"

const FactsWrapper = styled.div`
  background: linear-gradient(to bottom, #dfcec7 0%, #d16c71 70%);
  width: 100%;
  padding: 0 .5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media ${device.laptop} {
    background: linear-gradient(to bottom, #dfcec7 0%, #da8e91 80%);
    flex-direction: row;
    height: auto;
    padding: 0 0 2rem 0;
  }
`;

const Facts = ({factsContent}) => {
  return (
    <FactsWrapper id="facts">
      {
        factsContent.map((fact,index) => 
          <Fact title={fact.title} items={fact.items} key={index} />
        )
      }
    </FactsWrapper>
  );
}

export default Facts;