import React from 'react';
import styled from "styled-components";
import { device } from "../../styles/devices"
import { MobileContactButton } from '../shared/mobileContactButton';

const HeroContactWrapper = styled.div`
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.laptop} {
    display: inline;
  }
`;

const HeroButton = styled.button`
  ${MobileContactButton}

  @media ${device.laptop} {
    border: 4px solid #fff;
    position: absolute;
    bottom: 4rem;
    right: 15%;
    width: auto;
    color: #fff;
    padding: 1rem 2rem;
  }
`;

const Herocontact = ({openContactForm, title}) => {
  return (
    <HeroContactWrapper>
      <HeroButton onClick={() => openContactForm()}>{ title }</HeroButton>
    </HeroContactWrapper>
  );
}

export default Herocontact;
