import React from 'react';
import styled, { css } from "styled-components";
import { device } from "../../styles/devices"

const headerStyle = css`
  letter-spacing: 2px;
  margin-bottom: 2rem;
`;

const AnjaHeader = styled.h2`
  ${headerStyle}

  @media ${device.laptop} {
    grid-area: anjaHeader;
  }
`;

const RoccaHeader = styled.h2`
  ${headerStyle}
  text-align: right;
  
  @media ${device.laptop}{
    grid-area: roccaHeader;
  }
`;

const Name = styled.span`
  ${headerStyle}
  color: ${props => props.theme.red};
`;

const Headers = ({anja, rocca}) => {
  return (
    <>
      <AnjaHeader>{ anja.black } <Name>{ anja.red }</Name></AnjaHeader>
      <RoccaHeader>{ rocca.black } <Name>{ rocca.red }</Name></RoccaHeader>
    </>
  );
}

export default Headers;
