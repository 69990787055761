import styled from "styled-components";
import React from 'react';

const CloseButtonWrapper = styled.button`
  background: transparent;
  border: none;
  color: ${props => props.theme.red};
  float: right;
  align-self: flex-end;

  &:hover {
    cursor: pointer;
  }

  span {
    font-size: 50px;
  }
`;

const CloseButton = ({onClickHandler}) => {
  return (
    <CloseButtonWrapper 
      onClick={onClickHandler}
    >
      <span className="material-icons">close</span>
    </CloseButtonWrapper>
  );
}

export default CloseButton;
