import React from 'react';
import styled from "styled-components";
import HeaderLogo from "../../images/header_logo_cropped.png";
import { device } from "../../styles/devices"

const HeroBackground = styled.div`
  background: linear-gradient(to bottom, #e1bab8 40%, #d16c71 100%);
  height: 80%;

  @media ${device.laptop} {
    background: linear-gradient(to bottom, #e1bab8 0%, #d99293 20%, #d16c71 50%);
    height: 100%;
  }
`;

const HeroLogoContainer = styled.div`
  margin: 0 auto;
  width: 90%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeroLogo = styled.img`
  max-width: 100%;
  position: absolute;
  bottom: 0;

  @media ${device.laptop} {
    max-height: 100%;
  }
`;


const HeroImage = () => {
  return (
    <HeroBackground>
      <HeroLogoContainer>
        <HeroLogo src={HeaderLogo} />
      </HeroLogoContainer>
    </HeroBackground>
  );
}

export default HeroImage;
