import React from 'react';
import styled from 'styled-components';
import { MobileContactButton } from '../shared/mobileContactButton';
import { device } from "../../styles/devices"

const ContactWrapper = styled.section`
  width: 100%;
  padding: 0 5%;
  display: flex;
  flex-direction: column;

  @media ${device.laptop} {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 5rem auto;
  }
`;

const Unicorn = styled.img`
  width: 100%;

  @media ${device.laptop} {
    width: 40%;
  }
`;

const Header = styled.h1`
  margin-bottom: 0;

  span {
    color: ${props => props.theme.red};
  }

  @media ${device.laptop} {
    margin: 1rem 0;
  }
`;

const Paragraph = styled.p`
  margin: 0;
  letter-spacing: .5px;
  white-space: pre-line;
  
  @media ${device.laptop} {
    width: 60%;
  }
`;

const ContactButton = styled.button`
  ${MobileContactButton};
  width: 100%;
  display: block;
  margin: 2rem auto;

  @media ${device.laptop} {
    width: auto;
    padding: 1rem 2rem;
    margin-top: 2.5rem;
    margin-left: 0;
  }
`;

const InfoWrapper = styled.div`
  @media ${device.laptop} {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ContactText = styled.div`
  color: ${props => props.theme.red};
  margin-bottom: 3rem;
  text-align: center;
  white-space: pre;
`;

const Contact = ({openContactForm, contactContent}) => {
  return (
    <ContactWrapper id="contact">
      <Unicorn src={contactContent.image} />
      <InfoWrapper>
        <Header><span>{ contactContent.header.red }</span>{ contactContent.header.black }</Header>
        <Paragraph>
          { contactContent.content }
        </Paragraph>
        <ContactButton onClick={() => openContactForm()}>{ contactContent.button }</ContactButton>
        <ContactText>{ contactContent.subtitle }</ContactText>
      </InfoWrapper>
    </ContactWrapper>
  );
}


export default Contact;