import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import CloseButton from './closeButton';
import ContactService from './contactService';
import ContactForm from './form/contactForm';
import ErrorMessage from './messages/errorMessage';
import SuccessMessage from './messages/successMessage';

const ContactModal = ({ isOpen, closeHandler }) => {
  const initialState = {
    sending: false,
    error: false,
    success: false
  };
  const [{ sending, error, success}, setState] = useState(initialState);
  useEffect(() => {
    window.addEventListener('keyup', closeDialogOnEscapePress);

    return () => {
      window.removeEventListener('keyup', closeDialogOnEscapePress);
    }
  });
  Modal.setAppElement('body');

  const closeDialog = () => {
    setState(initialState);
    closeHandler()
  }

  const closeDialogOnEscapePress = e => {
    if(e.key === "Escape"){
      closeDialog();
    }
  };

  const sendContactRequest = contactForm => {
    if(!sending){
      setState({ sending: true });

      const contactService = new ContactService();
      
      if(contactService.isContactFormValid(contactForm)){
        contactService.sendContactRequest(contactForm)
        .then(result => {
          if(result){
            setState({ success: true });
          } else {
            setState({ error: true })
          }
        })
        .catch(() => {
          setState({ error: true })
        })
      } else {
        // not valid
      }

      setState({ sending: false });
    }
  }

  return (
    <div>
      <Modal 
        isOpen={isOpen}
        id="contact-modal"
      >
        <CloseButton onClickHandler={() => closeDialog()} />
        {
          !error && !success && <ContactForm submitHandler={sendContactRequest} />
        }
        {
          error && <ErrorMessage />
        }
        {
          success && <SuccessMessage />
        }
      </Modal>
    </div>
  );
}

export default ContactModal;
