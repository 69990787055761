import React from 'react';
import styled from 'styled-components';
import { device } from '../../../styles/devices';
import HandSvg from "../../../svg/Hand_Ok_Pink.svg";


const Success = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    width: 150px;
    margin: 2rem 0 1rem 0;
  }

  p {
    color: ${props => props.theme.red};
    font-size: 30px;
  }
  
  @media ${device.laptop} {
    padding-left: 1.5rem;
  }
`;

const SuccessMessage = () => {
  return (
    <Success>
      <HandSvg />
      <p>
        Nachricht erfolgreich versendet!
      </p>
    </Success>
  );
}

export default SuccessMessage;
