import axios from "axios";
import qs from 'qs';
import { MAIL_API, MAIL_API_KEY } from 'gatsby-env-variables'

class ContactService {
  constructor(){
    this.apiUrl = MAIL_API;
    this.apiKey = MAIL_API_KEY;
  }

  isContactFormValid(contactForm){
    const name = contactForm.name;
    const email = contactForm.email;
    const message = contactForm.message;
    const privacyChecked = contactForm.privacyChecked;

    return isNotEmpty(name) && isNotEmpty(message) && isValidEmail(email) && privacyChecked;
  };

  sendContactRequest(contactForm){
    return new Promise((resolve, reject) => {
      const options = {
        method: 'POST',
        headers: { 
          'content-type': 'application/x-www-form-urlencoded', 
          'apikey': this.apiKey 
        },
        data: qs.stringify({
          'customerName': contactForm.name,
          'customerMailAddress': contactForm.email,
          'customerPhone': contactForm.phone,
          'mailText': contactForm.message
        }),
        url: this.apiUrl
      };

      axios(options)
        .then(result => {
          resolve(result.data.success);
        })
        .catch(() => {
          reject()
        })
    })
  }
}

const isNotEmpty = string => {
  let isValid = false;

  if(string && string.trim() !== ''){
    isValid = true;
  }

  return isValid;
};

const isValidEmail = email => {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(String(email).toLowerCase());
};

export { isValidEmail };
export default ContactService;
