import React from 'react';
import styled from 'styled-components';
import { device } from '../../../styles/devices';

const TextAreaWrapper = styled.textarea`
  border: 4px solid ${props => props.theme.red};
  font-size: 1.5rem;
  color: ${props => props.theme.red};
  text-align: center;
  width: 100%;
  resize: vertical;
  line-height: 3rem;
  height: calc(100% - 1.5rem);
  text-indent: .75rem;

  @media ${device.laptop} {
    text-align: left;
  }
`;

const Error = styled.div`
  z-index: 2;
  height: 1.2rem;
  font-size: 1rem;
`;

const TextAreaContainer = styled.div`
  margin-top: .5rem;
`;

const TextArea = ({ error, style, ...props }) => {
  return (
    <TextAreaContainer style={style} >
      <Error><b>{error}</b></Error>
      <TextAreaWrapper {...props}  />
    </TextAreaContainer>
  );
}

export default TextArea;
