import React from 'react';
import styled from 'styled-components';
import { device } from '../../../styles/devices';

const InputElement = styled.input`
  border: 4px solid ${props => props.theme.red};
  width: 100%;
  font-size: 1.5rem;
  color: ${props => props.theme.red};
  text-align: center;
  z-index: 1;
  background: transparent;
  margin-bottom: 1rem;
  outline: none;
  text-indent: .75rem;
  height: calc(100% - 1.5rem);
  -webkit-appearance: none;

  @media ${device.laptop} {
    text-align: initial;
    border-width: 5px;
  }
`;

const Error = styled.div`
  z-index: 2;
  height: 1.2rem;
  font-size: 1rem;
`;

const InputWrapper = styled.div`
  margin-top: .5rem;
`;

const Input = ({ error, style, ...props }) => {
  return (
    <InputWrapper style={style}>
      <Error><b>{ error }</b></Error>
      <InputElement {...props } />
    </InputWrapper>
  );
}

export default Input;
